import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Client, Account } from 'appwrite';
import '../App.css'; // Assuming your styles are stored here
import logo from '../assets/logo.png'; // Replace with your logo path

const client = new Client();
client.setEndpoint('https://cloud.appwrite.io/v1') // Replace with your Appwrite endpoint
    .setProject('6748fa610024d013c88b');      // Replace with your project ID

const account = new Account(client);

const Header = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    account.get()
        .then(setUser)
        .catch(() => setUser(null));
  }, []);

  const handleLogout = async () => {
    try {
      await account.deleteSession('current');
      navigate('/login'); // Redirect to login
    } catch (error) {
      console.error("Logout error:", error);
    }
  };
  const clearCache = () => {
    localStorage.removeItem('universities');
    localStorage.removeItem('universities_timestamp');
    console.log('Cache cleared');
  };


  return (
      <header className="custom-header">
        <div className="header-content">
          <Link to="/" className="logo-link">
            <img src={logo} alt="Logo" className="header-logo" />
          </Link>
          <nav className="nav-bar">
            {user && ( // Check if the user is logged in
                <>
                  <Link to="/About" className="nav-link">About</Link>
                  <Link to="/contactus" className="nav-link">Contact Us</Link>
                  <Link to="/PrivacyPolicy" className="nav-link">Privacy Policy</Link>
                </>
            )}
          </nav>

          <div className="user-info">
            {user ? (
                <div className="user-menu">
                  <button className="greeting-dropdown">
                    Hi, {user.name}
                    <span className="arrow-icon">▼</span>
                  </button>
                  <div className="dropdown-content">
                    <button onClick={handleLogout} className="logout-button">Sign Out</button>
                    <button onClick={clearCache} className="logout-button">
                      Clear Cache
                    </button>
                  </div>
                </div>
            ) : (
                <>
                  <Link to="/login" className="login-button">Sign In</Link>
                </>
            )}
          </div>
        </div>
      </header>
  );
};

export default Header;
