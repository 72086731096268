import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Client, Account } from 'appwrite';
import logo from '../assets/logo.png';

const client = new Client();
client.setEndpoint('https://cloud.appwrite.io/v1') // Replace with your Appwrite endpoint
    .setProject('6748fa610024d013c88b');    // Replace with your project ID

const account = new Account(client);

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await account.createEmailPasswordSession(email, password);
      navigate('/');
    } catch (error) {
      console.error(error); // Log the error
      setError(error.message || 'Failed to login. Please check your credentials.');
    }
  };

  return (
      <div style={loginPageStyle}>
        <div style={loginContainerStyle}>
          <img src={logo} alt="Logo" style={logoStyle} />
          <h1 style={loginTitleStyle}>You're Early</h1>
          <h3 style={loginSubTitleStyle}>If you don't have a login, Come back soon!</h3>
          <form onSubmit={handleLogin}>
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                style={inputStyle}
            />
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                style={inputStyle}
            />
            {error && <p style={errorStyle}>{error}</p>}
            <button type="submit" style={loginButtonStyle}>Login</button>
          </form>
        </div>
      </div>
  );
};

const loginPageStyle = {
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#f4f4f4',
};

const loginContainerStyle = {
  width: '350px',
  padding: '30px',
  backgroundColor: 'white',
  borderRadius: '10px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
};

const logoStyle = {
  width: '300px',
  marginBottom: '20px',
};

const loginTitleStyle = {
  fontSize: '24px',
  color: '#333',
  marginBottom: '20px',
};
const loginSubTitleStyle = {
  fontSize: '16px',
  color: '#333',
  marginBottom: '20px',
};

const inputStyle = {
  width: '100%',
  padding: '10px',
  marginBottom: '15px',
  borderRadius: '5px',
  border: '1px solid #ccc',
  fontSize: '16px',
};

const errorStyle = {
  color: 'red',
  marginBottom: '15px',
};

const loginButtonStyle = {
  width: '100%',
  padding: '10px',
  backgroundColor: '#007bff',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  fontSize: '16px',
  cursor: 'pointer',
};

export default Login;