import { Client, Storage } from 'appwrite';

const client = new Client();
client.setEndpoint('https://cloud.appwrite.io/v1') // Replace with your Appwrite endpoint
    .setProject('6748fa610024d013c88b');      // Replace with your project ID

const storage = new Storage(client);

export const fetchUniversities = async () => {
  try {
    const bucketId = '1'; // Replace with your bucket ID
    const files = await storage.listFiles(bucketId); // List all files in the bucket

    const universities = await Promise.all(
        files.files.map(async (file) => {
          const fileUrl = storage.getFileView(bucketId, file.$id); // Get file URL
          const response = await fetch(fileUrl);
          return response.json(); // Parse JSON data
        })
    );

    return universities;
  } catch (error) {
    console.error('Error fetching universities from bucket:', error);
    throw error;
  }
};
