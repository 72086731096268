import React from 'react';
import logo from '../assets/logo.png'; // Replace with your logo's path
import '../App.css'; // Assuming you're using the same CSS file for styling

const PrivacyPolicy = () => {
    return (
        <div className="about-page">
            {/* Logo at the top */}
            <img src={logo} alt="EDU Finders Logo" className="about-logo" />

            <h1>Privacy Policy</h1>

            <h2>Effective Date</h2>
            <p>20th of Dec 2024</p>

            <h2>Information We Collect</h2>
            <p>
                We collect information to provide and improve our services. This includes:
            </p>
            <ul>
                <li><strong>Personal Information:</strong> Name, email address, and login credentials.</li>
                <li><strong>Usage Information:</strong> IP address, device type, and pages visited.</li>
                <li><strong>University and Program Preferences:</strong> Search queries, filters applied, and favorites.</li>
                <li><strong>Cookies:</strong> Data to remember your preferences and enhance your experience.</li>
            </ul>

            <h2>How We Use Your Information</h2>
            <p>
                The information we collect is used to:
            </p>
            <ul>
                <li>Provide personalized university recommendations.</li>
                <li>Enhance the user experience on our platform.</li>
                <li>Communicate updates, promotions, and responses to inquiries.</li>
                <li>Analyze usage trends to improve features.</li>
                <li>Ensure compliance with applicable laws.</li>
            </ul>

            <h2>Sharing Your Information</h2>
            <p>
                We do not sell or rent your personal data. However, we may share information with:
            </p>
            <ul>
                <li><strong>Service Providers:</strong> Third parties who assist in providing essential services such as hosting and analytics.</li>
                <li><strong>University Partners:</strong> Aggregated data for performance insights.</li>
                <li><strong>Legal Authorities:</strong> To comply with legal requirements or protect rights.</li>
            </ul>

            <h2>Data Security</h2>
            <p>
                We implement reasonable measures to protect your data. However, no system is entirely secure. Please contact us if you believe your information has been compromised.
            </p>

            <h2>Data Retention</h2>
            <p>
                Personal data is retained as long as necessary to provide our services or comply with legal obligations. Cached data is refreshed periodically to ensure accuracy.
            </p>

            <h2>Cookies and Tracking</h2>
            <p>
                We use cookies to enhance functionality and provide personalized recommendations. You can manage cookies through your browser settings.
            </p>

            <h2>Your Rights</h2>
            <p>
                You have the right to:
            </p>
            <ul>
                <li>Access and update your personal information.</li>
                <li>Request data deletion by contacting us.</li>
                <li>Manage cookie preferences in your browser.</li>
            </ul>

            <h2>Changes to This Policy</h2>
            <p>
                We may update this Privacy Policy periodically. Please check this page for updates. The revised policy will include the effective date at the top.
            </p>

            <h2>Contact Us</h2>
            <p>
                If you have any questions, feedback, or concerns, please contact us:
            </p>
            <ul>
                <li>Email: <a href="mailto:hi@edufinders.com">hi@edufinders.com</a></li>
                <li>Phone: +20 107 021 9376</li>
            </ul>
        </div>
    );
};

export default PrivacyPolicy;