import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import UniversityList from './components/UniversityList';
import UniversityDetail from './components/UniversityDetail';
import Header from './components/Header';
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import Login from './components/login';
import About from './components/About';
import MajorDetail from './components/MajorDetail';
import { fetchUniversities } from './api';
import SpinnerImage from './assets/spinner.png';
import ScrollToTop from './components/ScrollToTop';
import ContactUs from "./components/ContactUs";
import { Client, Account } from 'appwrite';
import PrivacyPolicy from "./components/PrivacyPolicy";

const client = new Client();
client.setEndpoint('https://cloud.appwrite.io/v1') // Replace with your Appwrite endpoint
    .setProject('6748fa610024d013c88b');      // Replace with your project ID

const account = new Account(client);

function App() {
  const [universities, setUniversities] = useState([]);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const cachedSession = localStorage.getItem('userSession');
        if (cachedSession) {
          setCurrentUser(JSON.parse(cachedSession));
        } else {
          const session = await account.get();
          setCurrentUser(session);
          localStorage.setItem('userSession', JSON.stringify(session));
        }
      } catch (error) {
        console.error('No active session:', error);
        setCurrentUser(null);
      } finally {
        setAuthLoading(false);
      }
    };

    initializeAuth();
  }, []);

  useEffect(() => {
    const loadUniversities = async () => {
      try {
        const cachedUniversities = localStorage.getItem('universities');
        if (cachedUniversities) {
          setUniversities(JSON.parse(cachedUniversities));
        } else {
          const universitiesData = await fetchUniversities();
          setUniversities(universitiesData);
          localStorage.setItem('universities', JSON.stringify(universitiesData));
                    const oneDay = 1 * 60 * 60 * 1000; // 24 hours in milliseconds
          localStorage.setItem('universities_expiration', oneDay);
        }
      } catch (error) {
        console.error('Error fetching universities:', error);
        setError('Failed to load universities.');
      }
    };

    loadUniversities();
  }, []);

  useEffect(() => {
    if (!authLoading) {
      setTimeout(() => {
        setFadeOut(true);
      }, 100);

      setTimeout(() => setShowSpinner(false), 900);
    }
  }, [authLoading]);

  if (showSpinner) {
    return (
        <div className={`loading-spinner ${fadeOut ? 'fade-out' : ''}`}>
          <img src={SpinnerImage} alt="Loading..." className="spinner-image"/>
          <h2 className={"h2"}>Loading</h2>
        </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
      <Router>
        <AppContent
            universities={universities}
            currentUser={currentUser}
            authLoading={authLoading}
        />
      </Router>
  );
}

function AppContent({ universities, currentUser, authLoading }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [fadeClass, setFadeClass] = useState('page-transition');
  const hideHeaderAndFooter = location.pathname === '/login';

  useEffect(() => {
    setFadeClass('page-transition-exit');
    const timeoutId = setTimeout(() => {
      setFadeClass('page-transition');
    }, 200);

    return () => clearTimeout(timeoutId);
  }, [location]);

  const requireAuth = (Component) => {
    if (!currentUser && !authLoading) {
      return <Navigate to="/login" />;
    }
    return <Component />;
  };

  return (
      <div className={fadeClass}>
        {!hideHeaderAndFooter && <Header />}
        <ScrollToTop />
        <Routes>
          <Route path="/" element={requireAuth(() => <UniversityList universities={universities} />)} />
          <Route path="/login" element={<Login />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/university/:id" element={requireAuth(() => <UniversityDetail universities={universities} />)} />
          <Route path="/university/:uniId/majors/:majorName" element={requireAuth(() => <MajorDetail universities={universities} />)} />
          <Route path="/about" element={requireAuth(() => <About />)} />
          <Route path="/contactus" element={requireAuth(() => <ContactUs />)} />
          <Route path="*" element={<NotFound />} />
        </Routes>

        {!hideHeaderAndFooter && (
            <div className="footer-color">
              <Footer />
            </div>
        )}
      </div>
  );
}

export default App;
