import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import { FaFacebook, FaInstagram, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import { FaXTwitter, FaWhatsapp } from 'react-icons/fa6'; // Ensure this import works
import logo from '../assets/logo.png'; // Replace with your logo's path
import '../App.css'; // Assuming you're using the same CSS file
import { Client, Account } from 'appwrite';

const client = new Client();
client.setEndpoint('https://cloud.appwrite.io/v1')
    .setProject('6748fa610024d013c88b');

const account = new Account(client);

const Footer = () => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const checkUser = async () => {
            try {
                const session = await account.get();
                setUser(session);
            } catch {
                setUser(null);
            }
        };

        checkUser();
    }, []);

    return (
        <footer className="custom-footer">
            <div className="footer-links">
                <img src={logo} alt="EDU Finders Logo" className="footer-logo" />
            </div>
            {user && ( // Check if the user is logged in
                <>
                    <div className="social-icons">
                        <a href="https://facebook.com/edufinderss" target="_blank" rel="noopener noreferrer">
                            <FaFacebook />
                        </a>
                        <a href="https://x.com/EDU_Finders" target="_blank" rel="noopener noreferrer">
                            <FaXTwitter />
                        </a>
                        <a href="https://www.instagram.com/edufinders/" target="_blank" rel="noopener noreferrer">
                            <FaInstagram />
                        </a>
                        <a href="https://www.linkedin.com/company/edufinders/" target="_blank" rel="noopener noreferrer">
                            <FaLinkedin />
                        </a>
                        <a href="https://wa.me/201070219376" target="_blank" rel="noopener noreferrer">
                            <FaWhatsapp />
                        </a>
                        <a href="mailto:hi@edufinders.com">
                            <FaEnvelope />
                        </a>
                    </div>
                    <div className="footer-links">
                        <Link to="/about" className="footer-about-link">About</Link>
                        <Link to="/contactus" className="footer-about-link">Contact Us</Link>
                    </div>
                </>
            )}
            <p>© 2024 EDU Finders</p>
        </footer>
    );
};

export default Footer;
